import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationDataPartB,
  applicationDataPartBPresentAddress,
} from "../../../../../../redux/actions";
import InputField from "../../../../../../Utilities/InputField/InputField";

const PartB = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const displayName = (inputData) => {
    dispatch(
      applicationDataPartB({
        displayName: inputData,
      })
    );
  };

  const house = (inputData) => {
    dispatch(
      applicationDataPartBPresentAddress({
        house: inputData,
      })
    );
  };

  const street = (inputData) => {
    dispatch(
      applicationDataPartBPresentAddress({
        street: inputData,
      })
    );
  };

  const city = (inputData) => {
    dispatch(
      applicationDataPartBPresentAddress({
        city: inputData,
      })
    );
  };

  const ps = (inputData) => {
    dispatch(
      applicationDataPartBPresentAddress({
        ps: inputData,
      })
    );
  };

  const dist = (inputData) => {
    dispatch(
      applicationDataPartBPresentAddress({
        dist: inputData,
      })
    );
  };

  const zip = (inputData) => {
    dispatch(
      applicationDataPartBPresentAddress({
        zip: inputData,
      })
    );
  };

  const spouseName = (inputData) => {
    dispatch(
      applicationDataPartB({
        spouseName: inputData,
      })
    );
  };

  const email = (inputData) => {
    dispatch(
      applicationDataPartB({
        email: inputData,
      })
    );
  };

  const mobile = (inputData) => {
    dispatch(
      applicationDataPartB({
        mobile: inputData,
      })
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.applicantsInformation.name.label")}`}
            name="name"
            disable
            defaultValue={myApplication.partB.name}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.displayName.label"
            )}`}
            name="displayName"
            execute={displayName}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={myApplication.partB.displayName}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.fatherName.label"
            )}`}
            name="fatherName"
            disable
            defaultValue={myApplication.partB.fathersName}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.motherName.label"
            )}`}
            name="motherName"
            disable
            defaultValue={myApplication.partB.mothersName}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.spouseName.label"
            )}`}
            name="spouseName"
            execute={spouseName}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={
              myApplication.partB.spouseName
                ? myApplication.partB.spouseName
                : ""
            }
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.nationality.label"
            )}`}
            name="nationality"
            disable
            defaultValue={myApplication.partB.nationality}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.nationalIDNumber.label"
            )}`}
            name="national_id_number"
            disable
            defaultValue={myApplication.partB.nid}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.dateOfBirth.label"
            )}`}
            name="dob"
            disable
            defaultValue={myApplication.partB.dob}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`Mobile Number (Own/Registered-for SMS Eg: +8801XXXXXXXXX)`}
            name="mobile_number"
            execute={mobile}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={myApplication.partB.mobile}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.applicantsInformation.gender.label")}`}
            name="gender"
            // placeholder="Male"
            disable
            defaultValue={myApplication.partB.gender}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.bloodGroup.label"
            )}`}
            name="blood_group"
            // placeholder="A+"
            disable
            defaultValue={myApplication.partB.bloodGroup}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.emailAddress.label"
            )}`}
            name="email_address"
            execute={email}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={myApplication.partB.email}
          />
        </div>
      </div>

      {/* ---------------------- Permanent Address ---------------------- */}
      <div className="confirm-header-secondary-text mt-4">
        {t("NEWAPPLICATION.addressInformation.permanentAddressHeader")}
      </div>
      <div className="row">
        <div className="col-12 mt-2">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.addressInformation.houseNumber.label"
            )}`}
            name="house_number"
            // placeholder="12/13 CE"
            disable
            defaultValue={myApplication.partB.address.permanentAddress.house}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.street.label")}`}
            name="road_no"
            // placeholder="32"
            disable
            defaultValue={myApplication.partB.address.permanentAddress.street}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.city.label")}`}
            name="city"
            // placeholder="Dhaka"
            disable
            defaultValue={myApplication.partB.address.permanentAddress.city}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.addressInformation.policeStation.label"
            )}`}
            name="police_station"
            // placeholder="Dhaka"
            disable
            defaultValue={myApplication.partB.address.permanentAddress.ps}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.district.label")}`}
            name="district"
            // placeholder="Dhaka"
            disable
            defaultValue={myApplication.partB.address.permanentAddress.dist}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.postCode.label")}`}
            name="post_code"
            // placeholder="1200"
            disable
            defaultValue={myApplication.partB.address.permanentAddress.zip}
          />
        </div>
      </div>

      {/* ---------------------- Present Address ---------------------- */}
      <div className="confirm-header-secondary-text mt-4">
        {t("NEWAPPLICATION.addressInformation.presentAddressHeader")}
      </div>
      <div className="row">
        <div className="col-12 mt-2">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.addressInformation.houseNumber.label"
            )}`}
            name="house_number"
            execute={house}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={myApplication.partB.address.presentAddress.house}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.street.label")}`}
            name="road_no"
            execute={street}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={myApplication.partB.address.presentAddress.street}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.city.label")}`}
            name="city"
            execute={city}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={myApplication.partB.address.presentAddress.city}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.addressInformation.policeStation.label"
            )}`}
            name="police_station"
            execute={ps}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={myApplication.partB.address.presentAddress.ps}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.district.label")}`}
            name="district"
            execute={dist}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={myApplication.partB.address.presentAddress.dist}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.postCode.label")}`}
            name="post_code"
            execute={zip}
            disable={myApplication.partA.issueType === "Initial"}
            defaultValue={myApplication.partB.address.presentAddress.zip}
          />
        </div>
      </div>
    </>
  );
};

export default PartB;
