import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { applicationDataPartC } from "../../../../../../redux/actions";
import InputField from "../../../../../../Utilities/InputField/InputField";
import Selector from "../../../../../../Utilities/Selector/Selector";
import TextInputArea from "../../../../../../Utilities/TextInputArea/TextInputArea";
import { useEffect, useState } from "react";

const PartC_ASP = () => {
  const [isGovt, setIsGovt] = useState(false);
  const [isAirside, setIsAirside] = useState(false);
  const [isNatureOfDutyModified, setIsNatureOfDutyModified] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );
  const myCurrentStates = myApplication.partC;

  const natureOrganization = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.govt"
      )}`,
      value: "GOVT",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.apbe"
      )}`,
      value: "APBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.atbe"
      )}`,
      value: "ATBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.ehci"
      )}`,
      value: "EHCI",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.cotn"
      )}`,
      value: "COTN",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.prvt"
      )}`,
      value: "PRVT",
    },
  ];

  const scopeOperation = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
      )}`,
      value: "",
    },
    // {
    //   label: `${t(
    //     "NEWAPPLICATION.detailsEmployment.scopeOperation.option.international"
    //   )}`,
    //   value: "International",
    // },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.scopeOperation.option.domestic"
      )}`,
      value: "Domestic",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.scopeOperation.option.both"
      )}`,
      value: "International and Domestic",
    },
  ];

  const natureDuty = [
    {
      label: `${t("NEWAPPLICATION.detailsEmployment.natureDuty.placeholder")}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureDuty.option.operational"
      )}`,
      value: "Operational",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureDuty.option.nonOperational"
      )}`,
      value: "Non-operational",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureDuty.option.diplomatic"
      )}`,
      value: "Government / Diplomatic Protocol",
    },
  ];

  const locationPrimary = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.option.airside"
      )}`,
      value: "Airside",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.option.terminalAirport"
      )}`,
      value: "Terminal (Airport / Air Transport Business)",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.option.terminalGovt"
      )}`,
      value: "Terminal (Govt / Diplomatic Protocol)",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.option.generalAviation"
      )}`,
      value: "General Aviation",
    },
  ];

  const employmentTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.deputation"
      )}`,
      value: "Deputation",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.permanent"
      )}`,
      value: "Permanent",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.contractual"
      )}`,
      value: "Contractual",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.other"
      )}`,
      value: "Other (Specify)",
    },
  ];

  useEffect(() => {
    // if (
    //   myCurrentStates.typeOfEmployment === "Other (Specify)" &&
    //   !myCurrentStates.typeOfEmploymentOther
    // )
    //   setCheckedValue(false);

    if (myCurrentStates.natureOfDuty === "Government / Diplomatic Protocol")
      setIsGovt(true);

    if (
      myCurrentStates.locationOfDuty !==
      "Terminal (Airport / Air Transport Business)"
    )
      setIsAirside(true);
  }, [myCurrentStates]);

  const designation = (inputData) => {
    dispatch(
      applicationDataPartC({
        designation: inputData,
      })
    );
  };

  const currentPosition = (inputData) => {
    dispatch(
      applicationDataPartC({
        currentPosition: inputData,
      })
    );
  };

  const typeOfEmployment = (selectedData) => {
    dispatch(
      applicationDataPartC({
        typeOfEmployment: selectedData,
        typeOfEmploymentOther: "",
      })
    );
  };

  const typeOfEmploymentOther = (inputData) => {
    dispatch(
      applicationDataPartC({
        typeOfEmploymentOther: inputData,
      })
    );
  };

  const natureOfDuty = (selectedData) => {
    if (selectedData === "Government / Diplomatic Protocol") {
      setIsNatureOfDutyModified(true);
      setIsGovt(true);
      setIsAirside(true);
      dispatch(
        applicationDataPartC({
          locationOfDuty: "Terminal (Govt / Diplomatic Protocol)",
          scopeOfOperation: "International and Domestic",
        })
      );
    } else {
      setIsNatureOfDutyModified(true);
      setIsGovt(false);
      setIsAirside(false);
      dispatch(
        applicationDataPartC({
          locationOfDuty: "",
          scopeOfOperation: "",
        })
      );
    }

    dispatch(
      applicationDataPartC({
        natureOfDuty: selectedData,
      })
    );
  };

  const locationOfDuty = (selectedData) => {
    setIsNatureOfDutyModified(false);
    if (selectedData === "Terminal (Airport / Air Transport Business)") {
      setIsAirside(false);
      dispatch(
        applicationDataPartC({
          scopeOfOperation: "",
        })
      );
    } else {
      setIsAirside(true);
      dispatch(
        applicationDataPartC({
          scopeOfOperation: "International and Domestic",
        })
      );
    }

    dispatch(
      applicationDataPartC({
        locationOfDuty: selectedData,
      })
    );
  };

  const scopeOfOperation = (selectedData) => {
    setIsNatureOfDutyModified(false);
    dispatch(
      applicationDataPartC({
        scopeOfOperation: selectedData,
      })
    );
  };

  return (
    <>
      <div className="confirm-header-title-text mt-5">
        {`${t("NEWAPPLICATION.confirmApplication.header.partC")}`}
      </div>
      <hr />
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.organizationName.label"
            )}`}
            name="organizationName"
            // placeholder="Government"
            disable
            defaultValue={myApplication.partC.organizationName}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            name="natureOfOrganization"
            placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.natureOfOrganization}
          /> */}

          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            listItem={natureOrganization}
            defaultValue={
              myApplication.partC.natureOfOrganization
                ? myApplication.partC.natureOfOrganization
                : ""
            }
            disable
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              myApplication.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            execute={designation}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.designation}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="current_position"
            execute={currentPosition}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.currentPosition}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            execute={typeOfEmployment}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.typeOfEmployment}
          /> */}
          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            placeholder={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
            )}`}
            listItem={employmentTypes}
            defaultValue={myApplication.partC.typeOfEmployment}
            execute={typeOfEmployment}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
          />
        </div>
        {myApplication.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              execute={typeOfEmploymentOther}
              disabled={
                myApplication.partA.issueType !==
                "Re-issue (Designation Change)"
              }
              defaultValue={myApplication.partC.typeOfEmploymentOther}
            />
          </div>
        )}

        {myApplication.partA.issueType === "Re-issue (Designation Change)" ? (
          <>
            <div className="col-12 mt-3">
              <Selector
                defaultValue={
                  myCurrentStates.natureOfDuty
                    ? myCurrentStates.natureOfDuty
                    : ""
                }
                execute={natureOfDuty}
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.natureDuty.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.detailsEmployment.natureDuty.placeholder"
                )}`}
                listItem={natureDuty}
              />
            </div>

            {isNatureOfDutyModified && (
              <>
                {isGovt && (
                  <div className="col-12 mt-3">
                    <Selector
                      defaultValue={
                        myCurrentStates.locationOfDuty
                          ? myCurrentStates.locationOfDuty
                          : ""
                      }
                      execute={locationOfDuty}
                      label={`${t(
                        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
                      )}`}
                      placeholder={`${t(
                        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
                      )}`}
                      listItem={locationPrimary}
                      disable={isGovt}
                    />
                  </div>
                )}

                {!isGovt && (
                  <div className="col-12 mt-3">
                    <Selector
                      defaultValue={
                        myCurrentStates.locationOfDuty
                          ? myCurrentStates.locationOfDuty
                          : ""
                      }
                      execute={locationOfDuty}
                      label={`${t(
                        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
                      )}`}
                      placeholder={`${t(
                        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
                      )}`}
                      listItem={locationPrimary}
                      // disable={isGovt}
                    />
                  </div>
                )}

                {isAirside && (
                  <div className="col-12 mt-3">
                    <Selector
                      defaultValue={
                        myCurrentStates.scopeOfOperation
                          ? myCurrentStates.scopeOfOperation
                          : ""
                      }
                      execute={scopeOfOperation}
                      label={`${t(
                        "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
                      )}`}
                      placeholder={`${t(
                        "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
                      )}`}
                      listItem={scopeOperation}
                      disable={isAirside}
                    />
                  </div>
                )}

                {!isAirside && (
                  <div className="col-12 mt-3">
                    <Selector
                      defaultValue={
                        myCurrentStates.scopeOfOperation
                          ? myCurrentStates.scopeOfOperation
                          : ""
                      }
                      execute={scopeOfOperation}
                      label={`${t(
                        "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
                      )}`}
                      placeholder={`${t(
                        "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
                      )}`}
                      listItem={scopeOperation}
                      // disable={isAirside}
                    />
                  </div>
                )}
              </>
            )}

            {!isNatureOfDutyModified && (
              <>
                {isGovt && (
                  <div className="col-12 mt-3">
                    <Selector
                      defaultValue={
                        myCurrentStates.locationOfDuty
                          ? myCurrentStates.locationOfDuty
                          : ""
                      }
                      execute={locationOfDuty}
                      label={`${t(
                        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
                      )}`}
                      placeholder={`${t(
                        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
                      )}`}
                      listItem={locationPrimary}
                      disable={isGovt}
                    />
                  </div>
                )}

                {!isGovt && (
                  <div className="col-12 mt-3">
                    <Selector
                      defaultValue={
                        myCurrentStates.locationOfDuty
                          ? myCurrentStates.locationOfDuty
                          : ""
                      }
                      execute={locationOfDuty}
                      label={`${t(
                        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
                      )}`}
                      placeholder={`${t(
                        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
                      )}`}
                      listItem={locationPrimary}
                      // disable={isGovt}
                    />
                  </div>
                )}

                {isAirside && (
                  <div className="col-12 mt-3">
                    <Selector
                      defaultValue={
                        myCurrentStates.scopeOfOperation
                          ? myCurrentStates.scopeOfOperation
                          : ""
                      }
                      execute={scopeOfOperation}
                      label={`${t(
                        "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
                      )}`}
                      placeholder={`${t(
                        "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
                      )}`}
                      listItem={scopeOperation}
                      disable={isAirside}
                    />
                  </div>
                )}

                {!isAirside && (
                  <div className="col-12 mt-3">
                    <Selector
                      defaultValue={
                        myCurrentStates.scopeOfOperation
                          ? myCurrentStates.scopeOfOperation
                          : ""
                      }
                      execute={scopeOfOperation}
                      label={`${t(
                        "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
                      )}`}
                      placeholder={`${t(
                        "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
                      )}`}
                      listItem={scopeOperation}
                      // disable={isAirside}
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className="col-6 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.natureDuty.label"
                )}`}
                name="natureOfDuty"
                disable={true}
                defaultValue={myApplication.partC.natureOfDuty}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
                )}`}
                name="locationOfDuty"
                disable={true}
                defaultValue={myApplication.partC.locationOfDuty}
              />
            </div>
            <div className="col-6 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
                )}`}
                name="scopeOfOperation"
                execute={scopeOfOperation}
                disable={true}
                defaultValue={myApplication.partC.scopeOfOperation}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PartC_ASP;
