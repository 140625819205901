import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { applicationDataPartC } from "../../../../../../redux/actions";
import InputField from "../../../../../../Utilities/InputField/InputField";
import Selector from "../../../../../../Utilities/Selector/Selector";
import TextInputArea from "../../../../../../Utilities/TextInputArea/TextInputArea";

const PartC_NAP = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const natureOrganization = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.govt"
      )}`,
      value: "GOVT",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.apbe"
      )}`,
      value: "APBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.atbe"
      )}`,
      value: "ATBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.ehci"
      )}`,
      value: "EHCI",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.cotn"
      )}`,
      value: "COTN",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.prvt"
      )}`,
      value: "PRVT",
    },
  ];

  const employmentTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.deputation"
      )}`,
      value: "Deputation",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.permanent"
      )}`,
      value: "Permanent",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.contractual"
      )}`,
      value: "Contractual",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.other"
      )}`,
      value: "Other (Specify)",
    },
  ];

  const designation = (inputData) => {
    dispatch(
      applicationDataPartC({
        designation: inputData,
      })
    );
  };

  const currentPosition = (inputData) => {
    dispatch(
      applicationDataPartC({
        currentPosition: inputData,
      })
    );
  };

  const divisionName = (inputData) => {
    dispatch(
      applicationDataPartC({
        divisionName: inputData,
      })
    );
  };

  const typeOfEmployment = (selectedData) => {
    dispatch(
      applicationDataPartC({
        typeOfEmployment: selectedData,
        typeOfEmploymentOther: "",
      })
    );
  };

  const typeOfEmploymentOther = (inputData) => {
    dispatch(
      applicationDataPartC({
        typeOfEmploymentOther: inputData,
      })
    );
  };

  const natureOfDutyDescription = (inputData) => {
    dispatch(
      applicationDataPartC({
        natureOfDutyDescription: inputData,
      })
    );
  };

  return (
    <>
      <div className="confirm-header-title-text mt-5">
        {`${t("NEWAPPLICATION.confirmApplication.header.partC")}`}
      </div>
      <hr />
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.organizationName.label"
            )}`}
            name="organizationName"
            // placeholder="Government"
            disable
            defaultValue={myApplication.partC.organizationName}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            name="natureOfOrganization"
            placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.natureOfOrganization}
          /> */}
          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            listItem={natureOrganization}
            defaultValue={
              myApplication.partC.natureOfOrganization
                ? myApplication.partC.natureOfOrganization
                : ""
            }
            disable
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.divisionName.label"
            )}`}
            name="division_name"
            execute={divisionName}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.divisionName}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              myApplication.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            execute={designation}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.designation}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="current_position"
            execute={currentPosition}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.currentPosition}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
            name="date_Of_joining_in_CAAB"
            // placeholder="DD/MM/YY"
            disable
            defaultValue={myApplication.partC.dateOfJoining}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            execute={typeOfEmployment}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.typeOfEmployment}
          /> */}
          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            placeholder={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
            )}`}
            listItem={employmentTypes}
            defaultValue={myApplication.partC.typeOfEmployment}
            execute={typeOfEmployment}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
          />
        </div>
        {myApplication.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              execute={typeOfEmploymentOther}
              disabled={
                myApplication.partA.issueType !==
                "Re-issue (Designation Change)"
              }
              defaultValue={myApplication.partC.typeOfEmploymentOther}
            />
          </div>
        )}
        <div className="col-12 mt-3">
          <TextInputArea
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.describeNatureDuty.label"
            )}`}
            name="natureOfDutyDescription"
            execute={natureOfDutyDescription}
            disabled={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.natureOfDutyDescription}
          />
        </div>
        {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {t("NEWAPPLICATION.confirmApplication.approvedChairmanLabel")}
            </label>
            <li className="file-upload-view-item">
              <span className="file-upload-view__content ms-3">
                <FontAwesomeIcon
                  className="file-upload-view__icon"
                  icon={faFileArchive}
                />
                <p className="file-upload-view__text ms-2">
                  {myApplication.partC.natureOfDutyDescriptionFile
                    ? myApplication.partC.natureOfDutyDescriptionFile
                    : "============== NOT UPLOADED =============="}
                </p>
              </span>
            </li>
          </div>
        </div> */}
        {/* <PDFAttachment
          fileLink={
            myApplication.partC.natureOfDutyDescriptionFile
              ? myApplication.partC.natureOfDutyDescriptionFile
              : ""
          }
          filePlaceholder="Approved Office Order"
        /> */}
      </div>
    </>
  );
};

export default PartC_NAP;
