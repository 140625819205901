import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changePreviousState,
  changeActiveApplicationPart,
  popupActive,
  popupInActive,
  loaderActive,
  loaderInActive,
  popupVariety,
  resetApplicationState,
  resetApplicationData,
  applicationDataPartBPresentAddress,
  applicationDataPartB,
  applicationDataPartC,
  applicationDataPartD,
  applicationDataPartE,
} from "../../../../../../redux/actions";
import useHttp from "../../../../../../Http/useHttp";
import { useAuth } from "../../../../../../Authentication/AuthContext";
import userImage from "../../../../../../assets/images/defaultUser.jpg";
import RadioLabel from "../../../../../../Utilities/RadioLabel/RadioLabel";
import FormTitle from "../../Utilities/FormTitle/FormTitle";
import Stepper from "../../Utilities/Stepper/Stepper";

// import DisabledInput from "../../Utilities/DisabledInput/DisabledInput";
// import DisableAuthorizationArea from "../../Utilities/DisableAuthorizationArea/DisableAuthorizationArea";
// import TextInputAreaDisable from "../../Utilities/TextInputAreaDisable/TextInputAreaDisable";

import InputField from "../../../../../../Utilities/InputField/InputField";
import AuthorizationArea from "../../../../../../Utilities/AuthorizationArea/AuthorizationArea";
import TextInputArea from "../../../../../../Utilities/TextInputArea/TextInputArea";
import Selector from "../../../../../../Utilities/Selector/Selector";
// import PDFAttachment from "../../../../../../Utilities/PDFAttachment/PDFAttachment";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArchive,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import Attachments from "../../../../../../Utilities/Attachments/Attachments";
import ImageUpload from "../../../../../../Utilities/ImageUpload/ImageUpload";
import DateInput from "../../../../../../Utilities/DateInput/DateInput";

import ForeignNationals from "./ForeignNationals";
import PartC_AAIAP from "./PartC_AAIAP";
import PartC_CMAP from "./PartC_CMAP";
import PartC_ASP from "./PartC_ASP";
import PartC_NAP from "./PartC_NAP";
import PartC_NIAC from "./PartC_NIAC";
import PartB from "./PartB";
import PartA from "./PartA";

// custom Style
import "./ConfirmApplication.css";

const ConfirmApplication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [unlawful, setUnlawful] = useState("");
  const [violated, setViolated] = useState("");
  const [blacklisted, setBlacklisted] = useState("");
  const [isOk, setIsOk] = useState(false);

  const api = useHttp("authorized");
  const { logout } = useAuth();

  const passType = useSelector(
    (state) => state.newApplicationData.newApplication.partA.passType
  );

  const draftId = useSelector(
    (state) => state.newApplicationData.newApplication.partA.draftId
  );

  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  useEffect(() => {
    switch (passType) {
      case "ASP":
        dispatch(changeActiveApplicationPart("Part F"));
        break;
      case "CMAP":
        dispatch(changeActiveApplicationPart("Part F"));
        break;

      default:
        dispatch(changeActiveApplicationPart("Part D"));
        break;
    }
  }, [passType, dispatch]);

  useEffect(() => {
    if (unlawful === "no" && violated === "no" && blacklisted === "no")
      setIsOk(true);
    else setIsOk(false);
  }, [unlawful, violated, blacklisted]);

  const submitApplication = async () => {
    if (isOk) {
      dispatch(loaderActive());
      try {
        const resp = await api.get(`draft/submit/${draftId}`);
        // console.log(resp.data);
        dispatch(
          popupVariety({
            popupVariety: "success",
            popupTitle: "Your Application has been Submitted Successfully",
            popupSubtitle: "Please Check Your Dashboard for Continuous Updates",
          })
        );
        dispatch(loaderInActive());
        dispatch(popupActive());
        dispatch(resetApplicationState());
        dispatch(resetApplicationData());
      } catch (error) {
        console.log(error.response);
        dispatch(
          popupVariety({
            popupVariety: "error",
            popupTitle: "Application Error",
            popupSubtitle: error.response.data.message,
          })
        );
        dispatch(loaderInActive());
        dispatch(popupActive());
        setTimeout(() => {
          dispatch(popupInActive());
        }, 2000);
        if (error.response.data.status === 401) logout();
      }
    }
  };

  const submitApplicationForReIssue = async () => {
    const formData = {
      ...myApplication.partE,
      ...myApplication.partC,
      ...myApplication.attachments,
      ...myApplication.partB.address.presentAddress,

      passType: myApplication.partA.passType,
      profilePic: myApplication.partB.profilePic,
      policeVerificationFile: myApplication.partB.policeVerificationFile,
      policeVerificationValidTill:
        myApplication.partB.policeVerificationValidTill,
      gsatFile: myApplication.partB.gsatFile,
      gsatValidTill: myApplication.partB.gsatValidTill,

      displayName: myApplication.partB.displayName,
      spouseName: myApplication.partB.spouseName,
      email: myApplication.partB.email,
      mobile: myApplication.partB.mobile,

      // designation: myApplication.partC.designation,
      // currentPosition: myApplication.partC.currentPosition,

      signature: myApplication.partD.signature,
      draftId,
    };

    // console.log(formData);

    if (isOk) {
      dispatch(loaderActive());
      try {
        const resp = await api.post(`draft/reissue-submit`, formData);
        console.log(resp.data);
        dispatch(
          popupVariety({
            popupVariety: "success",
            popupTitle: "Your Application has been Submitted Successfully",
            popupSubtitle: "Please Check Your Dashboard for Continuous Updates",
          })
        );
        dispatch(loaderInActive());
        dispatch(popupActive());
        dispatch(resetApplicationState());
        dispatch(resetApplicationData());
      } catch (error) {
        console.log(error.response);
        dispatch(
          popupVariety({
            popupVariety: "error",
            popupTitle: "Application Error",
            popupSubtitle: error.response.data.message,
          })
        );
        dispatch(loaderInActive());
        dispatch(popupActive());
        setTimeout(() => {
          dispatch(popupInActive());
        }, 5000);
        if (error.response.data.status === 401) logout();
      }
    }
  };

  const createDate = () => {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  const AssetContainer = ({ fieldLabel, assetUrl, assetName }) => {
    return (
      <>
        <label className="form-label upload-picture-field-label_2">
          {fieldLabel}
        </label>

        <div
          className="file-upload-asset"
          onClick={() => (assetUrl ? window.open(assetUrl) : null)}
        >
          <FontAwesomeIcon
            className={`mx-3 iconArchive`}
            icon={faFileArchive}
          />
          <div className="assetFileName">
            {assetUrl ? assetName : "==== NOT UPLOADED ===="}
          </div>
          {assetUrl && (
            <FontAwesomeIcon
              className={`mx-3 iconDownload`}
              icon={faCloudDownloadAlt}
            />
          )}
        </div>
      </>
    );
  };

  const AssetNIAC = () => {
    return (
      <div className="assetContainer">
        <div className="leftPortion col-6  pb-1">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className="image-styling img-fluid img-thumbnail rounded"
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        <div className="rightPortion col-6  pb-1">
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter From Concerned Division"
              assetName="Forwarding Letter"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of NID Card"
              assetName="NID Card"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Letter of Authorization as CAAB Inspector form CAAB Chairman"
              assetName="Letter of Authorization"
              assetUrl={myApplication.partC.typeOfAuthorizationSafetyFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetNAP = () => {
    return (
      <div className="assetContainer">
        <div className="leftPortion col-6  pb-1">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className="image-styling img-fluid img-thumbnail rounded"
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        <div className="rightPortion col-6  pb-1">
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter From Concerned Division"
              assetName="Forwarding Letter"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of NID Card"
              assetName="NID Card"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Letter of Job Assignment form CAAB Chairman"
              assetName="Letter of Job Assignment"
              assetUrl={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetAAIAP = () => {
    return (
      <div className="assetContainer">
        <div className="leftPortion col-6  pb-1">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className="image-styling img-fluid img-thumbnail rounded"
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        <div className="rightPortion col-6  pb-1">
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter From Concerned Division"
              assetName="Forwarding Letter"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of NID Card"
              assetName="NID Card"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Letter of Job Assignment form CAAB Chairman / Ministry"
              assetName="REF Chairman / Ministry"
              assetUrl={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetCMAP = () => {
    return (
      <div className="assetContainer">
        <div className="leftPortion col-6  pb-1">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className="image-styling img-fluid img-thumbnail rounded"
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Certificate from FSR Division"
              assetName="Certificate from FSR Division"
              assetUrl={myApplication.attachments.certificateFSR}
            />
          </div>
        </div>

        <div className="rightPortion col-6  pb-1">
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter from Employer"
              assetName="Forwarding Letter from Employer"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of National Id"
              assetName="Copy of National Id"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Crew Id"
              assetName="Copy of Crew Id"
              assetUrl={myApplication.attachments.employeeId}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Crew License"
              assetName="Copy of Crew License"
              assetUrl={myApplication.attachments.employeeLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetASP = () => {
    return (
      <>
        {/* =================== For Govt Employee =================== */}
        {myApplication.attachments.selectedAttachmentGroupASP === "GovtEmp" && (
          <div className="assetContainer">
            <div className="leftPortion col-6  pb-1">
              <div className="picture-field">
                <label className="form-label upload-picture-field-label_2">
                  {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                </label>
                <img
                  src={
                    myApplication.partB.profilePic
                      ? myApplication.partB.profilePic
                      : userImage
                  }
                  className="image-styling img-fluid img-thumbnail rounded"
                  alt="user-pic"
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel={`${t(
                    "NEWAPPLICATION.confirmApplication.GSATFile"
                  )}`}
                  assetName="GSAT Certificate"
                  assetUrl={myApplication.partB.gsatFile}
                />
              </div>

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="gsatValidTill"
                  disable
                  defaultValue={myApplication.partB.gsatValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Driving License"
                  assetName="Copy of Driving License"
                  assetUrl={myApplication.attachments.drivingLicense}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Existing Pass Card in Case of Renewal"
                  assetName="Copy of Existing Pass Card"
                  assetUrl={myApplication.attachments.existingPermit}
                />
              </div>
            </div>

            <div className="rightPortion col-6  pb-1">
              <AssetContainer
                fieldLabel="Forwarding letter from the employer authorized official with
                confirmation statement that the initial background checks and the
                police verification checks has been conducted on the person"
                assetName="Forwarding letter from employer"
                assetUrl={myApplication.attachments.forwardingLetter}
              />

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of NID"
                  assetName="Copy of NID"
                  assetUrl={myApplication.attachments.copyOfNID}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Employee Id"
                  assetName="Copy of Employee Id"
                  assetUrl={myApplication.attachments.employeeId}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Posting order/ office order for the job assignment at airport"
                  assetName="Posting order/ office order"
                  assetUrl={myApplication.attachments.officeOrder}
                />
              </div>
            </div>
          </div>
        )}

        {/* =================== For Non Govt Employee =================== */}
        {myApplication.attachments.selectedAttachmentGroupASP ===
          "NonGovtEmp" && (
          <div className="assetContainer">
            <div className="leftPortion col-6  pb-1">
              <div className="picture-field">
                <label className="form-label upload-picture-field-label_2">
                  {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                </label>
                <img
                  src={
                    myApplication.partB.profilePic
                      ? myApplication.partB.profilePic
                      : userImage
                  }
                  className="image-styling img-fluid img-thumbnail rounded"
                  alt="user-pic"
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel={`${t(
                    "NEWAPPLICATION.confirmApplication.GSATFile"
                  )}`}
                  assetName="GSAT Certificate"
                  assetUrl={myApplication.partB.gsatFile}
                />
              </div>

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="gsatValidTill"
                  disable
                  defaultValue={myApplication.partB.gsatValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Driving License"
                  assetName="Copy of Driving License"
                  assetUrl={myApplication.attachments.drivingLicense}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Existing Pass Card in Case of Renewal"
                  assetName="Copy of Existing Pass Card"
                  assetUrl={myApplication.attachments.existingPermit}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Valid AOC/ Permission and/ or Flight Schedule that is approved by CAAB"
                  assetName="Valid AOC/ Permission and/ or Flight Schedule"
                  assetUrl={myApplication.attachments.validAOC}
                />
              </div>
            </div>

            <div className="rightPortion col-6  pb-1">
              <AssetContainer
                fieldLabel={`${t(
                  "NEWAPPLICATION.confirmApplication.policeVerificationFile"
                )}`}
                assetName="Police Verification"
                assetUrl={myApplication.partB.policeVerificationFile}
              />

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="policeVerificationValidTill"
                  disable
                  defaultValue={myApplication.partB.policeVerificationValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Forwarding letter from the employer"
                  assetName="Forwarding letter from employer"
                  assetUrl={myApplication.attachments.forwardingLetter}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of NID"
                  assetName="Copy of NID"
                  assetUrl={myApplication.attachments.copyOfNID}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Employee Id"
                  assetName="Copy of Employee Id"
                  assetUrl={myApplication.attachments.employeeId}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Complete Background Check By Employer"
                  assetName="Background Check"
                  assetUrl={myApplication.attachments.backgroundCheck}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Agreement with CAAB or other Organization "
                  assetName="Agreement with CAAB or other Organization"
                  assetUrl={myApplication.attachments.copyOfAgreement}
                />
              </div>
              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Work Order"
                  assetName="Copy of Work Order"
                  assetUrl={myApplication.attachments.copyOfWorkOrder}
                />
              </div>
            </div>
          </div>
        )}

        {/* =================== For Foreigners =================== */}
        {myApplication.attachments.selectedAttachmentGroupASP ===
          "Foreigners" && (
          <div className="assetContainer">
            <div className="leftPortion col-6  pb-1">
              <div className="picture-field">
                <label className="form-label upload-picture-field-label_2">
                  {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                </label>
                <img
                  src={
                    myApplication.partB.profilePic
                      ? myApplication.partB.profilePic
                      : userImage
                  }
                  className="image-styling img-fluid img-thumbnail rounded"
                  alt="user-pic"
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel={`${t(
                    "NEWAPPLICATION.confirmApplication.GSATFile"
                  )}`}
                  assetName="GSAT Certificate"
                  assetUrl={myApplication.partB.gsatFile}
                />
              </div>

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="gsatValidTill"
                  disable
                  defaultValue={myApplication.partB.gsatValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Existing Pass Card in Case of Renewal"
                  assetName="Copy of Existing Pass Card"
                  assetUrl={myApplication.attachments.existingPermit}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Recommendation from Bangladesh Investment Development Agency
                (BIDA)"
                  assetName="Recommendation from BIDA"
                  assetUrl={myApplication.attachments.recommendationBIDA}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Visa"
                  assetName="Copy of Visa"
                  assetUrl={myApplication.attachments.copyOfVisa}
                />
              </div>
            </div>

            <div className="rightPortion col-6  pb-1">
              <AssetContainer
                fieldLabel="Copy of Passport"
                assetName="Copy of Passport"
                assetUrl={myApplication.attachments.copyOfPassport}
              />

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of note verbale issued by the mission"
                  assetName="Copy of note verbale"
                  assetUrl={myApplication.attachments.noteVerbale}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Recommendation of concerned ministry or authority"
                  assetName="Recommendation of ministry"
                  assetUrl={myApplication.attachments.recommendationOfMinistry}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Security Clearance form Ministry of Home Affairs"
                  assetName="Security Clearance MOHA"
                  assetUrl={myApplication.attachments.securityClearanceMOHA}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Recommendation of Ministry of Foreign affairs by endorsing the
              application form"
                  assetName="Recommendation of Ministry of Foreign affairs"
                  assetUrl={myApplication.attachments.recommendationMOFA}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const textUploadSignature = (
    <>
      {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.upload")}{" "}
      <span className={`textBold`}>
        {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.jpg")}
      </span>{" "}
      {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.or")}{" "}
      <span className={`textBold`}>
        {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.png")}
      </span>{" "}
      {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.file")}{" "}
      <span className={`textBold`}>
        {t("NEWAPPLICATION.applicantsDeclaration.signatureAdviceText.size")}.
      </span>
    </>
  );

  const signatureUpload = (url) => {
    if (url) {
      dispatch(
        applicationDataPartD({
          signature: url,
        })
      );
    } else {
      dispatch(
        applicationDataPartD({
          signature: "",
        })
      );
    }
  };

  return (
    <>
      <FormTitle
        header={`${t("NEWAPPLICATION.confirmApplication.title")}`}
        subTitle={`${t("NEWAPPLICATION.confirmApplication.subTitle")}`}
      />
      <Stepper />

      {/*============================================= Part A =============================================*/}
      <>
        <div className="confirm-header-title-text mt-2">
          {t("NEWAPPLICATION.confirmApplication.header.partA")}
        </div>
        <hr />
        <PartA />
      </>

      {/*============================================= Part B =============================================*/}
      <>
        <div className="confirm-header-title-text mt-5">
          {t("NEWAPPLICATION.confirmApplication.header.partB")}
        </div>
        <hr />
        <PartB />
      </>

      {/*============================================= Part C =============================================*/}
      <>
        {passType === "NIAC" && <PartC_NIAC />}
        {passType === "NAP" && <PartC_NAP />}
        {passType === "ASP" && <PartC_ASP />}
        {passType === "CMAP" && <PartC_CMAP />}
        {passType === "AAIAP" && <PartC_AAIAP />}
      </>

      {/*============================================= Part E Foreigner Details =============================================*/}
      {(passType === "CMAP" || passType === "ASP") && (
        <>
          <div className="confirm-header-title-text mt-5">
            {t("NEWAPPLICATION.confirmApplication.header.partD")}
          </div>
          <hr />
          <ForeignNationals />
        </>
      )}

      {/*============================================= Part Attachment and Declaration =============================================*/}
      <>
        <div className="confirm-header-title-text mt-5">
          {t("NEWAPPLICATION.confirmApplication.header.partF.part")}{" "}
          {passType === "CMAP" || passType === "ASP"
            ? `${t("NEWAPPLICATION.confirmApplication.header.partF.F")}`
            : `${t("NEWAPPLICATION.confirmApplication.header.partF.D")}`}{" "}
          : {t("NEWAPPLICATION.confirmApplication.header.partF.text")}
        </div>
        <hr />
        <div className="row">
          {!!(myApplication.partA.issueType !== "Initial") ? (
            <Attachments mode="write" />
          ) : (
            <div className="col-12 mt-3">
              {passType === "NIAC" && <AssetNIAC />}
              {passType === "NAP" && <AssetNAP />}
              {passType === "ASP" && <AssetASP />}
              {passType === "CMAP" && <AssetCMAP />}
              {passType === "AAIAP" && <AssetAAIAP />}
            </div>
          )}

          <div className="col-12 mt-5">
            <div className="mb-2">
              <h5 className="group-list-text-header">
                {t("NEWAPPLICATION.applicantsDeclaration.headerText.text")}{" "}
                {passType}:
              </h5>
              <ul className="p-0 ps-3 mt-4">
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item1")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t(
                    "NEWAPPLICATION.applicantsDeclaration.textList.item2.text"
                  )}{" "}
                  {passType === "NIAC"
                    ? `${t(
                        "NEWAPPLICATION.applicantsDeclaration.textList.item2.textInspector"
                      )}`
                    : `${t(
                        "NEWAPPLICATION.applicantsDeclaration.textList.item2.textPurposes"
                      )}`}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item3")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item4")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item5")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item6")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item7")}
                </li>
              </ul>
            </div>
          </div>

          {/* ======== Self Declaration ======== */}
          <div
            className={`col-12 mt-5 p-4 ${
              isOk ? "self__declaration_green" : "self__declaration_red"
            }`}
          >
            <div>
              <h5 className="group-list-text-header">
                Self Declaration<span className="text-danger">*</span>
              </h5>
              <ul className="p-0 ps-3 mt-4">
                <li className="mb-3">
                  Have you ever been involved in any unlawful activities in any
                  airport in Bangladesh?
                  <div className="row mt-1">
                    <div className="col-4">
                      <RadioLabel
                        label={`Yes`}
                        id="unlawful"
                        name="unlawful"
                        value="yes"
                        execute={setUnlawful}
                        defaultValue={unlawful}
                      />
                    </div>
                    <div className="col-4">
                      <RadioLabel
                        label={`No`}
                        id="unlawful"
                        name="unlawful"
                        value="no"
                        execute={setUnlawful}
                        defaultValue={unlawful}
                      />
                    </div>
                  </div>
                </li>
                <li className="mb-3">
                  Have you ever violated any airport, immigration and customs
                  rules in any airport in Bangladesh?
                  <div className="row mt-1">
                    <div className="col-4">
                      <RadioLabel
                        label={`Yes`}
                        id="violated"
                        name="violated"
                        value="yes"
                        execute={setViolated}
                        defaultValue={violated}
                      />
                    </div>
                    <div className="col-4">
                      <RadioLabel
                        label={`No`}
                        id="violated"
                        name="violated"
                        value="no"
                        execute={setViolated}
                        defaultValue={violated}
                      />
                    </div>
                  </div>
                </li>
                <li className="mb-3">
                  Have you ever been blacklisted by Civil Aviation Authority of
                  Bangladesh for any misconduct in any airport in Bangladesh?
                  <div className="row mt-1">
                    <div className="col-4">
                      <RadioLabel
                        label={`Yes`}
                        id="blacklisted"
                        name="blacklisted"
                        value="yes"
                        execute={setBlacklisted}
                        defaultValue={blacklisted}
                      />
                    </div>
                    <div className="col-4">
                      <RadioLabel
                        label={`No`}
                        id="blacklisted"
                        name="blacklisted"
                        value="no"
                        execute={setBlacklisted}
                        defaultValue={blacklisted}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* ======== Sign and Date ======== */}

          {!!(myApplication.partA.issueType !== "Initial") ? (
            <div className="col-12 mt-5">
              <div className={`signArea`}>
                <label className={`form-label label`}>
                  {t(
                    "NEWAPPLICATION.applicantsDeclaration.uploadSignature.label"
                  )}
                </label>
                <span className="text-danger">*</span>

                <ImageUpload
                  instruction={textUploadSignature}
                  execute={signatureUpload}
                  endpoint="draft/upload/signature"
                  formData={{ draftId: draftId, signature: "" }}
                  dataKey="signature"
                  defaultValue={myApplication.partD.signature}
                />
              </div>
            </div>
          ) : (
            <div className="col-12 mt-5">
              <div className="signature-image-styling">
                <img
                  src={myApplication.partD.signature}
                  alt="signature-user"
                  className="signature-image-display"
                />
              </div>
              <p className="p-0 m-0 authorized-signatory-text mt-3">
                {t("NEWAPPLICATION.ApplicantDetail.date")}:{" "}
                <span className="authorized-signatory-text-bold">
                  {createDate()}
                </span>
              </p>
            </div>
          )}

          {/* ======== Buttons ======== */}
          <div className="col-12 my-5">
            <div className="btn-area-confirm float-end">
              <button
                type="button"
                className="btn btn-confirm-custom btn-custom-orange"
                onClick={() =>
                  dispatch(
                    changePreviousState(
                      !!(myApplication.partA.issueType !== "Initial") ? 7 : 1
                    )
                  )
                }
              >
                {t("NEWAPPLICATION.confirmApplication.editBtn")}
              </button>
              <button
                type="button"
                disabled={!isOk}
                className="btn btn-confirm-custom btn-custom-blue"
                onClick={
                  myApplication.partA.issueType === "Initial"
                    ? submitApplication
                    : submitApplicationForReIssue
                }
              >
                {t("NEWAPPLICATION.confirmApplication.confirmBtn")}
              </button>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ConfirmApplication;
