import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InputField from "../../../../../../Utilities/InputField/InputField";
import Selector from "../../../../../../Utilities/Selector/Selector";
import AuthorizationArea from "../../../../../../Utilities/AuthorizationArea/AuthorizationArea";

const IssueTypeNotInitial = () => {
  const { t } = useTranslation();
  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const CmapCategories = [
    {
      label: `Select Required CMAP Category`,
      value: "",
    },
    {
      label: `Pilot (Schedule Passenger Operation)`,
      value: "P-SPO",
    },
    {
      label: `Pilot (Helicopter)`,
      value: "P-H",
    },
    {
      label: `Pilot (Flight Cargo)`,
      value: "P-FC",
    },
    {
      label: `Cabin Crew (Schedule Passenger Operation)`,
      value: "CC-SPO",
    },
    {
      label: `Flying School Instructor (FSI)`,
      value: "FSI",
    },
    {
      label: `Flying School Trainee (FST)`,
      value: "FST",
    },
  ];

  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
        <InputField
          type="text"
          label={`Existing AvSecID`}
          name="existingPassNumber"
          disable
          defaultValue={myApplication.partA.existingPassNumber}
        />
      </div>

      {myApplication.partA.passType === "CMAP" && (
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <Selector
            label="Existing CMAP Type"
            placeholder="Select One"
            listItem={CmapCategories}
            disable
            defaultValue={
              myApplication.partA.existingCMAPcategory
                ? myApplication.partA.existingCMAPcategory
                : ""
            }
          />
        </div>
      )}

      {myApplication.partA.passType === "ASP" && (
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.typeIssueSelected.colorOfCard.label")}`}
            name="colorOfPass"
            disable
            defaultValue={myApplication.partA.colorOfPass}
          />
        </div>
      )}

      {myApplication.partA.passType === "ASP" && (
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
          <AuthorizationArea isDisable checkboxes={myApplication.partA} />
        </div>
      )}

      {myApplication.partA.issueType === "Re-issue (Lost /Stolen)" && (
        <>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.typeIssueSelected.gdNumber.label")}`}
              name="gdNumber"
              // placeholder="64166116165"
              disable
              defaultValue={myApplication.partA.gdNumber}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.typeIssueSelected.dateOfEntry.label"
              )}`}
              name="gdEntry"
              // placeholder="2021-12-26"
              disable
              defaultValue={myApplication.partA.gdEntry}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.typeIssueSelected.policeStationName.label"
              )}`}
              name="psName"
              // placeholder="Badda"
              disable
              defaultValue={myApplication.partA.psName}
            />
          </div>
        </>
      )}
    </>
  );
};

export default IssueTypeNotInitial;
