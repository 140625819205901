import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { applicationDataPartC } from "../../../../../../redux/actions";
import InputField from "../../../../../../Utilities/InputField/InputField";
import Selector from "../../../../../../Utilities/Selector/Selector";
import TextInputArea from "../../../../../../Utilities/TextInputArea/TextInputArea";

const PartC_NIAC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const authorizationTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeAuthorization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `National Aviation Security (AVSEC) Inspector`,
      value: "Security_AVSEC",
    },
    {
      label: `National Aviation Safety (ANS) Inspector`,
      value: "Safety_ANS",
    },
    {
      label: `National Aviation Safety (OPS) Inspector`,
      value: "Safety_OPS",
    },
    {
      label: `National Aviation Safety (AGA) Inspector`,
      value: "Safety_AGA",
    },
    {
      label: `National Aviation Safety (OPS/PEL) Inspector`,
      value: "Safety_OPS_PEL",
    },
    {
      label: `National Aviation Safety (AIR) Inspector`,
      value: "Safety_AIR",
    },
  ];

  const natureOrganization = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.govt"
      )}`,
      value: "GOVT",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.apbe"
      )}`,
      value: "APBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.atbe"
      )}`,
      value: "ATBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.ehci"
      )}`,
      value: "EHCI",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.cotn"
      )}`,
      value: "COTN",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.prvt"
      )}`,
      value: "PRVT",
    },
  ];

  const employmentTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.deputation"
      )}`,
      value: "Deputation",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.permanent"
      )}`,
      value: "Permanent",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.contractual"
      )}`,
      value: "Contractual",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.other"
      )}`,
      value: "Other (Specify)",
    },
  ];

  const designation = (inputData) => {
    dispatch(
      applicationDataPartC({
        designation: inputData,
      })
    );
  };

  const currentPosition = (inputData) => {
    dispatch(
      applicationDataPartC({
        currentPosition: inputData,
      })
    );
  };

  const divisionName = (inputData) => {
    dispatch(
      applicationDataPartC({
        divisionName: inputData,
      })
    );
  };

  const typeOfEmployment = (selectedData) => {
    dispatch(
      applicationDataPartC({
        typeOfEmployment: selectedData,
        typeOfEmploymentOther: "",
      })
    );
  };

  const typeOfEmploymentOther = (inputData) => {
    dispatch(
      applicationDataPartC({
        typeOfEmploymentOther: inputData,
      })
    );
  };

  const typeOfAuthorization = (selectedData) => {
    dispatch(
      applicationDataPartC({
        typeOfAuthorization: selectedData,
      })
    );
  };

  const typeOfAuthorizationSafety = (inputData) => {
    dispatch(
      applicationDataPartC({
        typeOfAuthorizationSafety: inputData,
      })
    );
  };

  return (
    <>
      <div className="confirm-header-title-text mt-5">
        {`${t("NEWAPPLICATION.confirmApplication.header.partC")}`}
      </div>
      <hr />
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.organizationName.label"
            )}`}
            name="organizationName"
            // placeholder="Government"
            disable
            defaultValue={myApplication.partC.organizationName}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            name="natureOfOrganization"
            placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.natureOfOrganization}
          /> */}
          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            listItem={natureOrganization}
            defaultValue={
              myApplication.partC.natureOfOrganization
                ? myApplication.partC.natureOfOrganization
                : ""
            }
            disable
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.divisionName.label"
            )}`}
            name="division_name"
            execute={divisionName}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.divisionName}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              myApplication.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            execute={designation}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.designation}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="current_position"
            execute={currentPosition}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.currentPosition}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
            name="date_Of_joining_in_CAAB"
            // placeholder="DD/MM/YY"
            disable
            defaultValue={myApplication.partC.dateOfJoining}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            execute={typeOfEmployment}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.typeOfEmployment}
          /> */}
          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            placeholder={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
            )}`}
            listItem={employmentTypes}
            defaultValue={myApplication.partC.typeOfEmployment}
            execute={typeOfEmployment}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
          />
        </div>
        {myApplication.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              execute={typeOfEmploymentOther}
              disabled={
                myApplication.partA.issueType !==
                "Re-issue (Designation Change)"
              }
              defaultValue={myApplication.partC.typeOfEmploymentOther}
            />
          </div>
        )}
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeAuthorization.label"
            )}`}
            name="typeOfAuthorization"
            // placeholder="National Aviation Safety Inspector"
            disable
            defaultValue={myApplication.partC.typeOfAuthorization}
          /> */}

          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeAuthorization.label"
            )}`}
            listItem={authorizationTypes}
            execute={typeOfAuthorization}
            disable={
              myApplication.partA.issueType !== "Re-issue (Designation Change)"
            }
            defaultValue={myApplication.partC.typeOfAuthorization}
          />
        </div>

        {myApplication.partC.typeOfAuthorization !== "Security_AVSEC" && (
          <>
            <div className="col-12 mt-3">
              <TextInputArea
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.typeAuthorization.specify"
                )}`}
                name="typeOfAuthorizationSafety"
                execute={typeOfAuthorizationSafety}
                disabled={
                  myApplication.partA.issueType !==
                  "Re-issue (Designation Change)"
                }
                defaultValue={myApplication.partC.typeOfAuthorizationSafety}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PartC_NIAC;
