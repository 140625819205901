import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InputField from "../../../../../../Utilities/InputField/InputField";
import Selector from "../../../../../../Utilities/Selector/Selector";
import IssueTypeNotInitial from "./IssueTypeNotInitial";

const PartA = () => {
  const { t } = useTranslation();
  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );
  const passType = myApplication.partA.passType;
  const availableSites = myApplication.partA.availableSites;

  let allSites = [
    {
      label: `Select The Required Airport`,
      value: "",
    },
  ];

  const formattedSiteValue = availableSites.map((site) => ({
    label: `${site.name} (${site.acronym})`,
    value: site.id,
  }));

  allSites = [...allSites, ...formattedSiteValue];

  return (
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
        <InputField
          type="text"
          label={`${t("NEWAPPLICATION.confirmApplication.organizationCode")}`}
          name="organization_code"
          disable
          defaultValue={myApplication.partA.orgCode}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
        <InputField
          type="text"
          label={`${t("NEWAPPLICATION.confirmApplication.typeCard")}`}
          name="type_card"
          disable
          defaultValue={myApplication.partA.passType}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
        <InputField
          type="text"
          label={`${t("NEWAPPLICATION.confirmApplication.typeIssue")}`}
          name="type_issue"
          disable
          defaultValue={myApplication.partA.issueType}
        />
      </div>
      {passType === "ASP" && (
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <Selector
            label="Selected Airport"
            listItem={allSites}
            disable
            defaultValue={
              myApplication.partA.siteId ? myApplication.partA.siteId : ""
            }
          />
        </div>
      )}
      {myApplication.partA.issueType !== "Initial" && <IssueTypeNotInitial />}
    </div>
  );
};

export default PartA;
