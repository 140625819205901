import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { applicationDataPartE } from "../../../../../../redux/actions";
import InputField from "../../../../../../Utilities/InputField/InputField";
import DateInput from "../../../../../../Utilities/DateInput/DateInput";

const ForeignNationals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const shouldDisableField = (myApplication) => {
    const isCitizen =
      myApplication.partB.nationality.toLowerCase().substr(0, 3) === "ban";
    const isInitial = myApplication.partA.issueType === "Initial";

    // Disable the field if the issueType is "Initial" or if the user is a citizen
    return isInitial || isCitizen;
  };

  const passType = useSelector(
    (state) => state.newApplicationData.newApplication.partA.passType
  );

  const passportDocumentNumber = (inputData) => {
    dispatch(
      applicationDataPartE({
        passportDocumentNumber: inputData,
      })
    );
  };

  const placeOfIssue = (inputData) => {
    dispatch(
      applicationDataPartE({
        placeOfIssue: inputData,
      })
    );
  };

  const typeOfVisa = (inputData) => {
    dispatch(
      applicationDataPartE({
        typeOfVisa: inputData,
      })
    );
  };

  const visaNumber = (inputData) => {
    dispatch(
      applicationDataPartE({
        visaNumber: inputData,
      })
    );
  };

  const workPermitNumber = (inputData) => {
    dispatch(
      applicationDataPartE({
        workPermitNumber: inputData,
      })
    );
  };

  const passportDocumentExpiryDate = (selectedData) => {
    dispatch(
      applicationDataPartE({
        passportDocumentExpiryDate: selectedData,
      })
    );
  };

  const visaExpiryDate = (selectedData) => {
    dispatch(
      applicationDataPartE({
        visaExpiryDate: selectedData,
      })
    );
  };

  const workPermitExpiryDate = (selectedData) => {
    dispatch(
      applicationDataPartE({
        workPermitExpiryDate: selectedData,
      })
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.foreignNational.nationality.label")}`}
            name="nationality"
            // placeholder="British"
            disable
            defaultValue={
              myApplication.partE.foreignNationality
                ? myApplication.partE.foreignNationality
                : "N/A"
            }
          />
        </div>
        {passType === "ASP" && (
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.foreignNational.natureAssignment.label"
              )}`}
              name="natureOfAssignmentBD"
              disable
              defaultValue={
                myApplication.partE.natureOfAssignmentBD
                  ? myApplication.partE.natureOfAssignmentBD
                  : "N/A"
              }
            />
          </div>
        )}
      </div>
      <div className="confirm-header-tertiary-text mt-4">
        {`${t("NEWAPPLICATION.foreignNational.travelDocumentHeader")}`}
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.foreignNational.passportNumber.label"
            )}`}
            name="passportDocumentNumber"
            execute={passportDocumentNumber}
            disable={shouldDisableField(myApplication)}
            defaultValue={myApplication.partE.passportDocumentNumber}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.foreignNational.placeIssue.label")}`}
            name="placeOfIssue"
            execute={placeOfIssue}
            disable={shouldDisableField(myApplication)}
            defaultValue={myApplication.partE.placeOfIssue}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          {/* <InputField
              type="text"
              label={`${t("NEWAPPLICATION.foreignNational.expiryDate.label")}`}
              name="passportDocumentExpiryDate"
              execute={passportDocumentExpiryDate}
              disable={myApplication.partA.issueType === "Initial"}
              defaultValue={
                myApplication.partE.passportDocumentExpiryDate
                  ? myApplication.partE.passportDocumentExpiryDate
                  : "N/A"
              }
            /> */}

          <DateInput
            defaultValue={myApplication.partE.passportDocumentExpiryDate}
            execute={passportDocumentExpiryDate}
            label={`${t("NEWAPPLICATION.foreignNational.expiryDate.label")}`}
            disable={shouldDisableField(myApplication)}
          />
        </div>
      </div>
      <div className="confirm-header-tertiary-text mt-4">
        {`${t("NEWAPPLICATION.foreignNational.visaHeader")}`}
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.foreignNational.typeVisa.label")}`}
            name="typeOfVisa"
            execute={typeOfVisa}
            disable={shouldDisableField(myApplication)}
            defaultValue={myApplication.partE.typeOfVisa}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          {/* <InputField
              type="text"
              label={`${t("NEWAPPLICATION.foreignNational.validityVisa.label")}`}
              name="visaExpiryDate"
              execute={visaExpiryDate}
              disable={myApplication.partA.issueType === "Initial"}
              defaultValue={
                myApplication.partE.visaExpiryDate
                  ? myApplication.partE.visaExpiryDate
                  : "N/A"
              }
            /> */}
          <DateInput
            defaultValue={myApplication.partE.visaExpiryDate}
            execute={visaExpiryDate}
            label={`${t("NEWAPPLICATION.foreignNational.validityVisa.label")}`}
            disable={shouldDisableField(myApplication)}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.foreignNational.visaNumber.label")}`}
            execute={visaNumber}
            name="visaNumber"
            disable={shouldDisableField(myApplication)}
            defaultValue={myApplication.partE.visaNumber}
          />
        </div>
      </div>
      <div className="confirm-header-tertiary-text mt-4">
        {`${t("NEWAPPLICATION.foreignNational.workPermitHeader")}`}
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.foreignNational.workPermitNumber.label"
            )}`}
            name="workPermitNumber"
            execute={workPermitNumber}
            disable={shouldDisableField(myApplication)}
            defaultValue={myApplication.partE.workPermitNumber}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          {/* <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.foreignNational.validityWorkPermit.label"
              )}`}
              name="workPermitExpiryDate"
              // placeholder="2021-12-27"
              disable
              defaultValue={
                myApplication.partE.workPermitExpiryDate
                  ? myApplication.partE.workPermitExpiryDate
                  : "N/A"
              }/> */}

          <DateInput
            defaultValue={myApplication.partE.workPermitExpiryDate}
            execute={workPermitExpiryDate}
            label={`${t(
              "NEWAPPLICATION.foreignNational.validityWorkPermit.label"
            )}`}
            disable={shouldDisableField(myApplication)}
          />
        </div>
      </div>
    </>
  );
};

export default ForeignNationals;
